import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

interface SingleShowcaseItemProps {
  name: string
  url: string
  description: string
  technologies: string[]
}

const SingleShowcaseItem: FC<SingleShowcaseItemProps> = ({
  name,
  url,
  description,
  technologies,
}) => (
  <li className="text-accent">
    <div className="text-gray-200 flex-grow">
      <span className="flex items-center text-xl font-bold">
        <a href={url}>{name}</a>
        <span className="inline-block ml-4 text-sm opacity-80">
          {url.replace('https://', '')}
        </span>
      </span>
      <p className="my-1">{description}</p>
    </div>
    <span className="text-gray-200 text-sm opacity-80">
      Built with: {technologies.join(',  ')}
    </span>
  </li>
)

const ShowcaseItems = () => {
  const {
    experience: { showcase },
  } = useStaticQuery<Queries.ShowcaseItemsQuery>(graphql`
    query ShowcaseItems {
      experience {
        showcase {
          name
          description
          technologies
          url
        }
      }
    }
  `)

  return (
    <ul className="list-disc grid lg:grid-cols-2 gap-x-20 gap-y-10 list">
      {showcase.map(({ name, description, technologies, url }) => (
        <SingleShowcaseItem
          name={name}
          description={description}
          technologies={technologies}
          url={url}
          key={url}
        />
      ))}
    </ul>
  )
}

export default ShowcaseItems
