import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
interface ActivityItemProps {
  timePeriod: string
  role: string
  company: Pick<Queries.Entity, 'name' | 'url'>
  description: string
}

const ActivityItem: FC<ActivityItemProps> = ({
  timePeriod,
  role,
  company,
  description,
}) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { delay: 0.1 } }}
        className="opacity-90"
        viewport={{ once: true }}
      >
        {timePeriod}
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { delay: 0.1 } }}
        viewport={{ once: true }}
        className="lg:col-span-5 mb-20 lg:mb-0"
      >
        <div>
          <h4 className="font-bold text-xl">{role}</h4>
          <h5>
            <span className="text-accent text-xl">@</span>{' '}
            <a href={company.url as string}>{company.name}</a>
          </h5>
        </div>
        <p className="mt-5">{description}</p>
      </motion.div>
    </>
  )
}

const ActivityCards = () => {
  const {
    experience: { activity },
  } = useStaticQuery<Queries.ActivityCardsQuery>(graphql`
    query ActivityCards {
      experience {
        activity {
          description
          role
          timePeriod
          company {
            name
            url
          }
        }
      }
    }
  `)
  console.log(activity)
  return (
    <div className="grid lg:grid-cols-6 lg:gap-y-20 gap-x-10">
      {activity.map(({ role, timePeriod, description, company }) => {
        return (
          <ActivityItem
            role={role}
            company={company}
            description={description}
            timePeriod={timePeriod}
            key={timePeriod}
          />
        )
      })}
    </div>
  )
}

export default ActivityCards
