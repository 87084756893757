import React, { FC } from 'react'
import ActivityCards from '../components/ExperiencePage/ActivityCards'
import ShowcaseItems from '../components/ExperiencePage/ShowcaseItems'
import { PageContainer } from '../components/blocks'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import ProjectsGrid from '../components/ExperiencePage/ProjectsGrid'
import SEO from "../components/SEO";

const SectionTitle: FC<{ title: string }> = ({ title }) => (
  <h2 className="mt-20 mb-14 text-4xl font-bold">{title}</h2>
)

const ExperiencePage = () => (
  <Layout>
    <PageContainer>
      <PageTitle title="Experience" />
      <SectionTitle title="Projects" />
      <ProjectsGrid />
      <SectionTitle title="Activity" />
      <div className="w-3/4">
        <ActivityCards />
      </div>

      <SectionTitle title="Showcase" />
      <ShowcaseItems />
    </PageContainer>
  </Layout>
)

export default ExperiencePage

export const Head = () => (
    <SEO title="Experience" pathname="/experience" />
)

