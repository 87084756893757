import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

interface ProjectCardProps {
  name: string
  url: string
  descriptionHtml: string
}

const ProjectCard: FC<ProjectCardProps> = ({ name, url, descriptionHtml }) => (
  <div className="">
    <h3 className="text-2xl font-primary font-bold text-gray-100">{name}</h3>
    <p
      className="mt-5 text-gray-100 font-primary leading-relaxed"
      dangerouslySetInnerHTML={{ __html: descriptionHtml }}
    />
    <a href={url} className="block mt-4 text-accent">
      Learn more {'->'}
    </a>
  </div>
)

const ProjectsGrid = () => {
  const {
    experience: { projects },
  } = useStaticQuery<Queries.ProjectsGridQuery>(graphql`
    query ProjectsGrid {
      experience {
        projects {
          name
          descriptionHtml
          url
        }
      }
    }
  `)

  return (
    <div className="grid lg:grid-cols-2 gap-20 mt-10">
      {projects.map(({ name, descriptionHtml, url }) => (
        <ProjectCard
          name={name}
          url={url}
          descriptionHtml={descriptionHtml}
          key={url}
        />
      ))}
    </div>
  )
}

export default ProjectsGrid
